<template>
  <div class="sign-in">
    <div class="container">
      <h4 class="text-center title mb-8">{{ $t("set_pass_accept_invite") }}</h4>
      <v-form ref="form" v-model="valid" lazy-validation @submit="onSubmit">
        <div class="errors mb-12" v-if="errors.length">
          <b>{{ $t("set_pass_accept_invite_error") }}</b>
          <ul>
            <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
          </ul>
        </div>
        <v-text-field
          type="password"
          v-model="newPassword"
          :rules="passwordRules"
          required
          :placeholder="$t('enter_password')"
          :label="$t('your_password')"
          autocomplete="new-password"
        ></v-text-field>
        <v-text-field
          type="password"
          v-model="newPassword2"
          :rules="passwordRules"
          required
          :placeholder="$t('enter_password')"
          :label="$t('confirm_your_password')"
          autocomplete="new-password"
        ></v-text-field>
        <div class="text-center mt-8">
          <v-btn type="submit" color="primary">{{ $t("accept_invite") }}</v-btn>
        </div>
      </v-form>
    </div>
  </div>
</template>

<style scoped lang="scss">
.errors ul {
  margin: auto;
  li {
    list-style-type: none;
  }
}
.container {
  max-width: 500px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.19);
  padding: 25px;
  margin: 30px auto;
  background-color: white;
}
</style>

<script>
import ACTIVATE_USER from "../graphql/ActivateUser.gql";
import router from "../router";

export default {
  name: "ActivateUser",
  data() {
    return {
      errors: [],
      newPassword: null,
      newPassword2: null,
      inviteToken: null,
      valid: null,
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => (v && v.length >= 8) || "Password must be at least 8 characters",
      ],
    };
  },
  methods: {
    async onSubmit(evt) {
      evt.preventDefault();
      const isValid = this.$refs.form.validate();

      if (this.newPassword != this.newPassword2) {
        alert("Passwords do not match");
        return;
      }
      console.log("isValid", isValid);
      if (!isValid) {
        return;
      }
      const inviteToken = this.$route.query.inviteToken ? this.$route.query.inviteToken : null;
      if (!inviteToken) {
        alert("A token is required");
        return;
      }
      this.errors = [];
      try {
        const activateResult = await this.$apollo.mutate({
          mutation: ACTIVATE_USER,
          variables: {
            inviteToken,
            password: this.newPassword,
          },
        });
        this.$store.dispatch("auth/signin", {
          authUser: activateResult.data.activateUser,
        });
        router.push({ name: "course-list" });
      } catch (error) {
        this.errors.push(error.toString().replace("Error: GraphQL error: ", "")); // Vue doesn't like non-native types as keys, hence string
        this.$store.dispatch("auth/signin", { authUser: null });
      }
    },
  },
};
</script>
